import { PRIVACY_URL } from "_shared/constants"
import { formCall } from "api";
import axios from "axios";
import { ResponsiveDialog } from "components/molecules/Dialog/ResponsiveDialog";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormRequest } from "types";

export const Home = () => {
    const { pathname } = useLocation();

    const referralCode = pathname.slice(1);
    const [privacyChecked, setPrivacyChecked] = useState<boolean>(false)
    const [mattinaChecked, setMorningChecked] = useState<boolean>(false)
    const [pomeChecked, setAfternoonChecked] = useState<boolean>(false)
    const [seraChecked, setEveningChecked] = useState<boolean>(false)
    const [submitLoading, setSubmitLoading] = useState<boolean>()
    const [submitSuccess, setSubmitSuccess] = useState<boolean | undefined>()
    const [submitMessage, setSubmitMessage] = useState<string | undefined>()

    const inputInitialValue: FormRequest = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        note: '',
        referralCode,
        preferenceMorning: false,
        preferenceAfternoon: false,
        preferenceEvening: false,
    }

    const [inputValues, setInputValues] = useState<FormRequest>(inputInitialValue);

    const resetInputs = () => {
        setInputValues(inputInitialValue)
        setPrivacyChecked(false)
        setMorningChecked(false)
        setAfternoonChecked(false)
        setEveningChecked(false)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setInputValues((prevValues) => ({ ...prevValues, [name]: checked }));
    };

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        const checkedAtLeastOne = inputValues.preferenceMorning || inputValues.preferenceAfternoon || inputValues.preferenceEvening
        if (inputRef.current) {
            inputRef.current.setCustomValidity(!checkedAtLeastOne ? "Seleziona almeno una delle tre opzioni." : "");
        }
    }, [inputRef, inputValues.preferenceMorning, inputValues.preferenceAfternoon, inputValues.preferenceEvening]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setSubmitLoading(true)
        const response = await formCall(inputValues);
        setSubmitLoading(false)
        const success = response.errorCode === 0

        setSubmitSuccess(success)
        setSubmitMessage(response.errorMessage)

        if (success) {
            resetInputs()
        }
    };

    return (
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-2">
                        <img className="logo" src="NBLogo.svg"></img>
                    </div>
                    <div className="col-md-12 text-center mb-2">
                        <h4 className="form-title">Nouvelle Beauté ti da il benvenuto<br></br>
                            <b><u>In regalo per te</u></b> un trattamento viso o corpo del valore di <b><u><span className="form-title-amount">chf</span> 120.-</u></b></h4>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                <div className="col-md-6 d-flex align-items-stretch">
                                    <div className="contact-wrap w-100 p-md-5 p-4 py-5">
                                        <h5 className="mb-4"><b>Compila il form per accedere a esclusivi vantaggi!</b></h5>
                                        <form id="contactForm" className="contactForm" onSubmit={handleSubmit} >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5 className="input-title ">Nome</h5>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="name" id="name" placeholder="Inserisci il tuo nome"
                                                            required value={inputValues.name} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="input-title ">Cognome</h5>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="surname" id="surname" placeholder="Inserisci il tuo cognome"
                                                            required value={inputValues.surname} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="input-title ">Indirizzo e-mail</h5>
                                                    <div className="form-group">
                                                        <input type="email" required className="form-control" name="email" id="email"
                                                            placeholder="Inserisci la tua e-mail" value={inputValues.email} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="input-title ">Numero di cellulare</h5>
                                                    <div className="form-group">
                                                        <input type="tel" pattern="(([\+]|00)[0-9]{2}){0,1}[0-9]{8,10}" className="form-control" name="phone"
                                                            id="phone" placeholder="Inserisci il tuo numero di cellulare" required value={inputValues.phone} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="input-title ">Fascia oraria di contatto di preferenza</h5>
                                                    <div className="form-group-checkbox preference">
                                                        <div className="form-group-checkbox mb-0">
                                                            <input type="checkbox" id="preferenceMorning" name="preferenceMorning" className="form-checkbox"
                                                                required={!(inputValues.preferenceMorning || inputValues.preferenceAfternoon || inputValues.preferenceEvening)}
                                                                checked={inputValues.preferenceMorning}
                                                                onChange={handleCheckboxChange} ref={inputRef} />
                                                            <label className="checkbox preferenceChoices">Mattina</label>
                                                        </div>
                                                        <div className="form-group-checkbox mb-0">
                                                            <input type="checkbox" id="preferenceAfternoon" name="preferenceAfternoon" className="form-checkbox"
                                                                checked={inputValues.preferenceAfternoon} onChange={handleCheckboxChange} />
                                                            <label className="checkbox preferenceChoices">Pomeriggio</label>
                                                        </div>
                                                        <div className="form-group-checkbox mb-0">
                                                            <input type="checkbox" id="preferenceEvening" name="preferenceEvening" className="form-checkbox"
                                                                checked={inputValues.preferenceEvening} onChange={handleCheckboxChange} />
                                                            <label className="checkbox preferenceChoices">Sera</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group-checkbox">
                                                        <input type="checkbox" id="privacy" name="privacy" className="form-checkbox" required checked={privacyChecked}
                                                            onChange={() => setPrivacyChecked(x => !x)} />
                                                        <label className="form-control checkbox"
                                                            htmlFor="privacy">Ho letto e accetto l'informativa sulla <a href={PRIVACY_URL}>privacy policy</a></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-primary">
                                                            Invia Form
                                                            {submitLoading && <i className="ml-2 fa fa-spinner fa-pulse fa-2xl"></i>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-stretch">
                                    <div className="info-wrap w-100 p-md-5 p-4 py-5 img">
                                        <h5 className="mb-4"><b>I nostri contatti</b></h5>
                                        <div className="dbox w-100 d-flex align-items-center fontsize18">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <a href="https://www.google.com/maps/dir//Via+al+Mulino+1,+Cadempino,+Svizzera/@45.8417345,8.7287171,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x478432f1f910aa19:0x8e46e714510c584e!2m2!1d8.9335785!2d46.0373221?hl=it&entry=ttu" target="_blank"><span className="fa fa-map-marker"></span></a>
                                            </div>
                                            <div className="text d-flex align-items-center justify-content-center">
                                                <p><a href="https://www.google.com/maps/dir//Via+al+Mulino+1,+Cadempino,+Svizzera/@45.8417345,8.7287171,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x478432f1f910aa19:0x8e46e714510c584e!2m2!1d8.9335785!2d46.0373221?hl=it&entry=ttu" target="_blank">
                                                    <span>Vieni a trovarci:</span> Via al Mulino 1, Cadempino</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center fontsize18">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <a href="https://wa.me:/+41762757345"><span className="fa fa-whatsapp"></span></a>
                                            </div>
                                            <div className="text d-flex align-items-center justify-content-center">
                                                <p><a href="https://wa.me:/+41762757345"><span>Whatsapp:</span>  +41 76 275 73 45</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center fontsize18">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <a href="tel://+41919671908"><span className="fa fa-phone"></span></a>
                                            </div>
                                            <div className="text d-flex align-items-center justify-content-center">
                                                <p><a href="tel://+41919671908"><span>Telefono:</span>  +41 91 967 19 08</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center fontsize18">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <a href="mailto:info@nbeaute.com" ><span className="fa fa-envelope"></span></a>
                                            </div>
                                            <div className="text d-flex align-items-center justify-content-center">
                                                <p><a href="mailto:info@nbeaute.com"><span>E-mail:</span>  info@nbeaute.com</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center fontsize18">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <a href="https://www.nbeaute.com/" target="_blank"><span className="fa fa-globe"></span></a>
                                            </div>
                                            <div className="text d-flex align-items-center justify-content-center">
                                                <p><a href="https://www.nbeaute.com/" target="_blank"><span>Website:</span>  nbeaute.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ResponsiveDialog
                open={submitSuccess !== undefined}
                message={submitMessage}
                success={submitSuccess}
                onClose={() => setSubmitSuccess(undefined)}
            />
        </section>)

}
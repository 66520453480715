import './App.css';
import { Routes, Route } from "react-router-dom";
import { MainBody, Home, NotFound } from 'components/organisms';


interface AppProps {
  title: string;
  description?: string
  body?: {
    first: string,
    second?: string
  }
}

export const App = (
  {
    title,
    description,
    body
  }: AppProps) => {

  return (
      <div className="App">       
        <div id="main">
          <Routes>
              <Route path="/" element={<MainBody />}>
                <Route index element={<NotFound />} />
                <Route path="/:referralCode" element={<Home />} />
              </Route>          
          </Routes>
        </div>
      </div>
  );
}

export default App;

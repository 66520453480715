import { BASE_URL_API } from "_shared/constants"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FormRequest, BaseResponse } from "types"


export const formCall = async (request: FormRequest) => {
    return await axios
        .post(`${BASE_URL_API}/Frontend/Form`, request)
        .then((response: AxiosResponse<BaseResponse>) => {
            return response.data
        })
        .catch((error: AxiosError<BaseResponse>) => {
            return error.response?.data
                ?? { errorCode: -1, errorMessage: "Errore, riprovare più tardi" } as BaseResponse
        })
} 
import React, { useRef } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import NotFoundLottie from 'assets/lottie/404.json'
import { WEBSITE_URL } from '_shared/constants';


export const NotFound = () => {
    const lottieRef = useRef<any>();
    return (
        <Box className='notfoundbox'>
            <Container>
                <div className='notfound-father'>
                        <Lottie
                            lottieRef={lottieRef}
                            animationData={NotFoundLottie}
                            loop
                            className='notfound'
                        />
                        <Typography variant="h6">
                            La pagina che stai cercando non esiste
                        </Typography>
                        <Button onClick={()=> window.location.href= WEBSITE_URL} className='btn btn-primary'>Torna indietro</Button>
                </div>
            </Container>
        </Box>
    );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { WEBSITE_URL } from '_shared/constants';

interface ResponsiveDialogProps {
  open: boolean,
  success?: boolean
  message?: string
  onClose: () => void
}

export const ResponsiveDialog = ({
  open,
  success,
  message,
  onClose
}: ResponsiveDialogProps) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const theme = useTheme();

  const handleClose = (wasSuccess?: boolean) => {
    if (wasSuccess) {
      window.location.href = WEBSITE_URL
      return
    }
    setIsOpen(false);
    onClose()
  };

  React.useEffect(() => { setIsOpen(open) }, [open])

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(success)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className='dialog-title dialog-body'>
          {success ? 'Buono attivato correttamente' : 'Ops, sembra essersi verificato un errore'}
        </DialogTitle>
        <DialogContent className='dialog-body' >
          <DialogContentText className='dialog-subtitle'>
            {success ? `Il tuo buono è stato correttamente inviato, Maura o Veronica ti contatteranno entro 24 ore per prenotare il tuo appuntamento!`
            : message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dialog-body'>
          <Button className='dialog-button' onClick={() => handleClose(success)}>
            {success ? `Vai al sito` : 'Riprova invio'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
